













































import _ from "lodash"
import { Vue, Component, Watch } from "vue-property-decorator"

@Component({ name: "login-callback" })
export default class LoginCallback extends Vue {
  loading: boolean = true
  errorType: string | null = null
  errorDescription: string | null = null

  get failed(): boolean {
    return !this.loading && !_.isNil(this.errorType)
  }

  get spinnerColor(): string {
    return this.errorType ? "error" : this.loading ? "primary" : "success"
  }

  @Watch("errorType")
  handleErrorType() {
    this.loading = false
  }

  async mounted() {
    let nextPath: string = "/"
    if (_.isString(this.$route.query.next)) {
      nextPath = this.$route.query.next
    }
    let toRoute = { path: nextPath }
    if (!_.isNil(this.$route.hash) && !_.isEmpty(this.$route.hash)) {
      this.loading = true
      try {
        await this.$auth.handleAuthHash(this.$route.hash)
        setTimeout(() => {
          this.loading = false
          this.$router.push(toRoute)
        }, 500)
      } catch (err) {
        if (
          _.get(err, "error") === "invalid_token" &&
          _.get(err, "errorDescription").startsWith("Issued At (iat) claim error in the ID token")
        ) {
          this.errorType = "Clock Mismatch Error"
          this.errorDescription =
            "This error is most likely caused by the browsing computer having a clock time that is not in sync with the actual time."
        } else {
          this.errorType = _.get(err, "errorType", "Unspecified Error")
          this.errorDescription = _.get(err, "errorDescription", err || "Unspecified Error")
        }
        this.$raven.captureException(new Error(this.errorDescription || undefined))
      } finally {
        this.loading = false
      }
    } else {
      this.$router.push(toRoute)
    }
  }
}
